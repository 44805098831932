import { Button, Paper, Stack, Typography } from '@mui/material';
import useLocales from '#/hooks/useLocales';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';

type Props = {
  open: boolean;
  closeDialog: () => void;
  changeAnswer: () => void;
};

const WarningStatusModal = ({ open, closeDialog, changeAnswer }: Props) => {
  const { translate } = useLocales();

  return (
    <DialogAnimate open={open} onClose={changeAnswer} maxWidth="md">
      <Paper
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          {String(translate('global.civil_statusWarning.title'))}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {String(translate('global.civil_statusWarning.description'))}
        </Typography>

        <Stack
          direction={{ xs: 'column' }}
          alignItems="center"
          spacing={2}
          justifyContent="center"
          sx={{ marginTop: 3 }}
        >
          <Button
            variant="contained"
            onClick={changeAnswer}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography>
              {String(translate('global.civil_statusWarning.cancel'))}
            </Typography>
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={closeDialog}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography>
              {String(translate('global.civil_statusWarning.update'))}
            </Typography>
          </Button>
        </Stack>
      </Paper>
    </DialogAnimate>
  );
};

export default WarningStatusModal;
