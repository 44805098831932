import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';
import useServiceStats from './useServiceStats';
import { IUserAccountGeneral } from '#/types/user';

type UseCheckCivilStatusProps = {
  user: any;
  watch: UseFormWatch<IUserAccountGeneral> | UseFormWatch<any>;
  handleOpenWarning: () => void;
};

const useCheckCivilStatus = ({
  user,
  watch,
  handleOpenWarning,
}: UseCheckCivilStatusProps) => {
  const [checkedOnce, setCheckedOnce] = useState(false);
  const { last_will } = useServiceStats();

  useEffect(() => {
    if (
      !checkedOnce &&
      last_will !== null &&
      user?.civil_status !== null &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      user?.civil_status !== watch('civil_status')
    ) {
      setCheckedOnce(true);
      handleOpenWarning();
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line
  }, [user?.civil_status, last_will, checkedOnce, watch('civil_status')]);
};

export default useCheckCivilStatus;
